














































































































































































































































































































































































import { Component } from 'vue-property-decorator'
import Header from '@/components/Header.vue'
import Alert from '@/components/Alert.vue'
import Button from '@/components/Button.vue'
import Content from '@/components/Content.vue'
import { Product } from '@/models/Product'
import { UserOrderItem } from '@/models/UserOrderItem'
import { firestore } from 'firebase/app'
import { ProductConverter } from '@/models/converters/ProductConverter'
import { FarmConverter } from '@/models/converters/FarmConverter'
import { FarmPeriodConverter } from '@/models/converters/FarmPeriodConverter'
import { FarmPeriod } from '@/models/FarmPeriod'
import { Farm } from '@/models/Farm'
import { formatDate } from '@/services/DateService'
import moment from 'moment'
import { showNotification } from '@/services/NotificationService'
import { formatMoney } from '@/services/NumberService'
import { mapBasicFeedingType } from '@/models/Types'
import CartMixin from '@/mixins/CartMixin.vue'
import { mixins } from 'vue-class-component'

@Component({
  components: {
    Header,
    Alert,
    Button,
    Content
  }
})
export default class Products extends mixins(CartMixin) {
  loading: boolean = false
  product: Product = null
  item: UserOrderItem = null

  notificationTimeout: any

  farms: Farm[] = []
  farmPeriods: { [id: string]: FarmPeriod[] } = {}

  amounts: any[] = []

  basicFeedingTypes: any[] = [
    { key: 'lean', value: mapBasicFeedingType('lean') },
    { key: 'medium', value: mapBasicFeedingType('medium') },
    { key: 'fat', value: mapBasicFeedingType('fat') }
  ]

  get isNotEntirePig(): boolean {
    return this.product.meta.pigs < 1
  }

  get pigs(): number {
    return this.product.meta.pigs
  }

  async created() {
    this.loading = true

    await this.loadProduct()
    await this.prepareOrderItem()

    document.title = `${this.product.name} – meinSCHWEIN`

    this.loading = false
  }

  async loadProduct() {
    const products = await firestore()
      .collection('products')
      .withConverter(new ProductConverter())
      .where('slug', '==', this.$route.params.slug)
      .where('deleted', '==', false)
      .limit(1)
      .get()

    if (products.docs.length > 0) {
      this.product = products.docs[0].data()
    }
  }

  async prepareOrderItem() {
    this.item = new UserOrderItem()
    this.item.product = this.product

    switch (this.product.type) {
      case 'pig': {
        await this.preparePigOrderItem()
        break
      }
      case 'credits':
      case 'coupon': {
        await this.prepareCouponCreditsOrderItem()
        break
      }
    }
  }

  async preparePigOrderItem() {
    this.farms = (
      await firestore()
        .collection('farms')
        .where('listed', '==', true)
        .where('has_shop', '==', true)
        .orderBy('order', 'asc')
        .withConverter(new FarmConverter())
        .get()
    ).docs.map((doc) => doc.data())

    for (const farm of this.farms) {
      const periods = (
        await firestore()
          .collection('farms')
          .doc(farm.id)
          .collection('periods')
          .withConverter(new FarmPeriodConverter())
          .where('slaughter_day', '>=', new Date())
          .orderBy('slaughter_day', 'asc')
          .get()
      ).docs.map((doc) => doc.data())

      this.farmPeriods[farm.id] = periods
    }

    // Show only farms with periods
    this.farms = this.farms.filter((farm) => this.farmPeriods[farm.id].length > 0)

    const farmId = (this.$route.query.farm_id as string) ?? this.farms[0].id

    const nextAvailablePeriodId = this.getNextPeriodIdWithCapacity(farmId)

    const periodId =
      this.$route.query.period_id ??
      nextAvailablePeriodId ??
      this.farmPeriods[this.farms[0].id][0].id

    const params: any = {
      farm_id: farmId,
      period_id: periodId
    }

    if (!this.isNotEntirePig) {
      params.basic_feeding = 'medium'
    }

    this.item.params = params
  }

  getNextPeriodIdWithCapacity(farmId: string) {
    const matchingPeriod = this.farmPeriods[farmId].find(
      (p) => p.availableCapacity >= this.pigs
    )
    if (matchingPeriod) {
      return matchingPeriod.id
    }

    return this.farmPeriods[farmId][0].id
  }

  async prepareCouponCreditsOrderItem() {
    for (let i = 1; i <= 50; i++) {
      const amount = 1000 * i

      this.amounts.push({
        amount,
        value: this.formatMoney(amount)
      })
    }

    this.item.params = {
      amount: this.amounts[0].amount
    }
  }

  addToCart(item: UserOrderItem) {
    if (this.item.product.type === 'pig') {
      this.item.displayParams = {
        farm_name: this.selectedFarm.name,
        setting_day: formatDate(this.selectedPeriod.settingDay),
        slaughter_day: formatDate(this.selectedPeriod.slaughterDay)
      }
      if (!this.isNotEntirePig) {
        this.item.displayParams.basic_feeding = this.basicFeedingTypes.find(
          (type) => type.key === this.item.params.basic_feeding
        ).value
      }
    }

    this.mainStore.addToCart(item)
    this.$router.push({ name: 'cart' })
    showNotification({
      text: `Das Produkt "${item.product.name}" wurde in den Warenkorb gelegt.`,
      title: 'Zum Warenkorb hinzugefügt',
      // link: {
      //   text: 'Zum Warenkorb',
      //   target: 'cart'
      // },
      type: 'success',
      timeout: 10
    })
  }

  get selectedFarm(): Farm {
    return this.farms.find((f) => f.id === this.item.params.farm_id)
  }

  get selectedPeriod(): FarmPeriod {
    return this.selectedFarm ? this.farmPeriods[this.selectedFarm.id].find(
      (p) => p.id === this.item.params.period_id
    ) : null
  }

  existingProductInCart() {
    const existingItem = this.mainStore.cart.items.find(
      (i: UserOrderItem) => this.item?.product && i.product.type === this.item.product.type
    )

    return !!existingItem
  }

  canAddToCart() {
    return (
      !this.existingProductInCart() &&
      ((this.product && this.selectedPeriod && this.product.type === 'pig' &&
        this.selectedPeriod.availableCapacity >= this.product.meta.pigs) ||
        this.product.type !== 'pig')
    )
  }

  formatMoney(amount: number) {
    return formatMoney(amount)
  }
}

