
import { Component } from 'vue-property-decorator'
import { UserOrderItem } from '@/models/UserOrderItem'
import UserMixin from '@/mixins/UserMixin.vue'

@Component({
  components: {}
})
export default class CartMixin extends UserMixin {
  get orderItems(): UserOrderItem[] {
    return this.mainStore.cart.items
  }

  get total(): number {
    return this.orderItems.reduce(
      (acc: any, item: UserOrderItem) => acc + item.calculatedAmount,
      0
    )
  }
}
